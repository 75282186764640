<template lang="pug">
  v-container(fluid class="pt-5 pl-10 pr-10 mb-10")
    v-row(class="text-center")
      v-col(class="mb-4")
        h1(class="text-h2 font-weight-light primary--text") CS Integrador

        p(class="text-center mt-5")
          | Nossa API de integração foi desenvolvida para ser genérica, utilizando padrões de mercado
          | como  
          a(href="https://pt.wikipedia.org/wiki/JSON") JSON
          | ,   
          a(href="https://pt.wikipedia.org/wiki/JSON_Web_Token") JWT
          | ,   
          a(href="https://pt.wikipedia.org/wiki/REST") REST
          |  e   
          a(href="https://pt.wikipedia.org/wiki/OAuth") OAuth
          | .
          br
          | Em caso de dúvidas e/ou necessidades encontradas no entendimento desta documentação, não exite em entrar em contato com o nosso setor técnico.

    v-divider

    v-row(class="text-center mt-5")
      v-col(class="mb-4")
        h3(class="text-h3 font-weight-light primary--text") Ambientes
        p(class="text-center mt-5")
          | Atualmente possuímos 2 ambientes do CS Integrador, um de produção e outro de homologação:
          br
          br
          strong Homologação: 
          span(class="primary--text") https://hml.integrador.controlsoft.com.br/api
          br
          strong Produção: 
          span(class="primary--text") https://integrador.controlsoft.com.br/api
          br
          br
          | A integração através do ambiente de produção depende totalmente da autorização por parte dos nossos clientes através do nosso ERP.


    v-divider
    v-timeline(align-top reverse)
      v-timeline-item(small color="primary")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Definição de senha de acesso 🔑
          v-card-text(class="white text-justify text--primary")
            p
              | Para realizar requisições para a API de Integração da ControlSoft
              | é necessário utilizar uma senha de acesso em conjunto com o seu CNPJ
            v-btn(class="font-weight-bold" outlined color="primary" @click="showAlterarSenha = !showAlterarSenha")
              | {{ showAlterarSenha ? "Cancelar" : "Alterar Senha" }}
            v-alert(type="success" class="mt-5" border="top" colored-border elevation="2" v-if="(tentouAlterar && senhaAlterada && !showAlterarSenha)") {{ msgAlteracaoSenha }}
          v-divider

          div(v-show="showAlterarSenha" class="ma-5")
            v-form(@submit.prevent="submit")
              v-text-field(label="Senha atual"
                            name="senha"
                            v-model="senha"
                            type="password"
                            prepend-inner-icon="mdi-key"
                            hide-details
                            required
                            color="primary"
                            class="mt-4")
              v-text-field(label="Nova senha"
                            name="senhaNova"
                            v-model="senhaNova"
                            type="password"
                            prepend-inner-icon="mdi-key-star"
                            hide-details
                            required
                            color="primary"
                            class="mt-4")
              br
              v-alert(type="error" class="mt-5" border="top" colored-border elevation="2" v-if="(!senhaAlterada && tentouAlterar)")  {{ msgAlteracaoSenha }}
              v-card-actions
                v-spacer
                v-btn(class="my-5 font-weight-bold" type="submit" outlined color="primary" v-on:click="alterarSenha") Alterar


      // AUTENTICAÇÃO

      v-timeline-item(small color="primary" class="timeline-box")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Autenticação 🔒
          v-card-text(class="white text-justify text--primary")
            p
              | Toda a comunicação de terceiros para troca de informações com clientes da ControlSoft será realizada através de autenticação utilizando como
              | base o padrão 
              strong OAuth
              | , que retornará um Token 
              strong JWT
              |  para ser utilizado nas requisições seguintes. Este Token JWT servirá para identificar quais
              | informações estarão acessíveis.
            v-btn(class="mr-4 font-weight-bold" outlined color="primary") POST
            span(class="primary--text") https://integrador.controlsoft.com.br/api/auth/login
          v-divider(class="mt-3")
          v-expansion-panels(accordion=false hover flat)
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Parâmetros
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title client_id
                    v-list-item-subtitle O seu CNPJ que foi autorizado para integração com algum cliente ControlSoft
                v-list-item
                  v-list-item-content
                    v-list-item-title client_secret
                    v-list-item-subtitle Senha cadastrada para integração
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Retorno
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title access_token
                    v-list-item-subtitle Bearer JWT para ser utilizado para as demais requisições
                v-list-item
                  v-list-item-content
                    v-list-item-title expires_in
                    v-list-item-subtitle Tempo de expiração do token JWT
                v-list-item
                  v-list-item-content
                    v-list-item-title user
                    v-list-item-subtitle Dados do seu usuário
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Possíveis códigos HTTP de retorno
              v-expansion-panel-content(class="ml-5")
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="success--text") 200
                  v-col(cols="12" sm="10") Sucesso
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 401
                  v-col(cols="12" sm="10") Usuario e/ou senha invalidos
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="red--text text--darken-2") 5xx
                  v-col(cols="12" sm="10") Erro interno na API do CS Integrador
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Exemplo de JSON de retorno
              v-expansion-panel-content(class="overflow-y-auto" style="max-height: 200px") {{JSONLogin}}


      // CLIENTES


      v-timeline-item(small color="primary" class="timeline-box")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Clientes 👨‍💼
          v-card-text(class="white text-justify text--primary")
            p
              | Com uma senha de acesso é possível realizar requisições HTTP
              | para realizar integrações com clientes da ControlSoft.
              | Para saber quais clientes autorizaram integrações com o seu CNPJ,
              | basta realizar a seguinte requisição à nossa API REST.
            v-btn(class="mr-4 font-weight-bold" outlined color="success" v-on:click="carregarClientes") GET
            span(class="primary--text") https://integrador.controlsoft.com.br/api/clientes
            v-btn(color="primary" icon v-on:click="carregarClientes")
              v-icon mdi-play-circle-outline
          DataTable(:fields="fieldsClientes"
                    :dados="dadosClientes"
                    :loaded="!!dadosClientes"
                    :order="orderClientes")
          v-divider(class="mt-3")
          v-expansion-panels(accordion=false hover flat)            
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Retorno
                small(class="ml-1 font-weight-light") (vetor)
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title cnpj
                    v-list-item-subtitle CNPJ do cliente ControlSoft que autorizou o consumo de informações
                v-list-item
                  v-list-item-content
                    v-list-item-title razao
                    v-list-item-subtitle Razão social do cliente ControlSoft
                v-list-item
                  v-list-item-content
                    v-list-item-title fantasia
                    v-list-item-subtitle Nome fantasia do cliente ControlSoft
              
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Possíveis códigos HTTP de retorno
              v-expansion-panel-content(class="ml-5")
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="success--text") 200
                  v-col(cols="12" sm="10") Sucesso
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 401
                  v-col(cols="12" sm="10") Token não informado
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Token inválido
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="red--text text--darken-2") 5xx
                  v-col(cols="12" sm="10") Erro interno na API do CS Integrador
            v-expansion-panel(v-if="!!dadosClientes")
              v-expansion-panel-header(class="text-h6") JSON de retorno
              v-expansion-panel-content(class="overflow-y-auto" style="max-height: 200px") {{ dadosClientes }}

      // ROMANEIOS


      v-timeline-item(small color="primary" class="timeline-box")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Romaneios 🚛
          v-card-text(class="white text-justify text--primary")
            p
              | Para buscar os Romaneios de um determinado cliente, basta realizar a seguinte requisição.
              br
              | Esta requisição irá retornar um vetor JSON contendo objetos com mais de 100 campos com informações sobre os romaneios que tiveram envolvimento com o seu CNPJ,
              | realizando filtro de informações sensíveis envolvendo informações de pessoas física para atender a LGPD.
              br
              | Por padrão, serão retornados 50 romaneios, podendo ser ajustado através das opções 
              strong limit 
              | e 
              strong last
              | .
            v-row
              v-col(cols="12" sm="9")
                v-btn(class="mr-4 font-weight-bold" outlined color="success" v-on:click="carregarRomaneios") GET
                span(class="primary--text") https://integrador.controlsoft.com.br/api/romaneios/:CNPJ
                v-btn(color="primary" icon v-on:click="carregarRomaneios")
                  v-icon mdi-play-circle-outline
              v-col(cols="12" sm="3")
                v-select(dense label="Chave (CNPJ)" :items="cnpjs" small v-model="cnpjRomaneio" no-data-text="Sem CNPJs autorizados")
          DataTable(:fields="fieldsRomaneios"
                    :dados="dadosRomaneios"
                    :loaded="!!dadosRomaneios"
                    :order="orderRomaneios")
          v-divider(class="mt-3")
          v-expansion-panels(accordion=false hover flat)
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Parametros informados na URL
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title cnpj
                    v-list-item-subtitle CNPJ do cliente ControlSoft que você deseja consultar os romaneios
              
          v-expansion-panels(accordion=false hover flat)
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Filtros possíveis
              v-expansion-panel-content
                v-list(three-line)
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Por ordem de romaneio
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/:CNPJ/:ROMANEIO
                      v-list-item-subtitle
                        strong ROMANEIO: 
                        | Deve ser um valor numérico,
                        | contendo o valor do campo 
                        strong codigodb 
                        | que é retornado na lista completa de romaneios
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Por data de lançamento
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/:CNPJ/:DATAINICIAL/:DATAFINAL
                      v-list-item-subtitle
                        strong DATAINICIAL: 
                        | Data inicial no padrão YYYY-MM-DD (Ano-Mês-Dia)
                        br
                        strong DATA FINAL: 
                        | Data final no padrão YYYY-MM-DD (Ano-Mês-Dia)
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Ajustando os limites
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/:CNPJ?limit=5&last=2
                      v-list-item-subtitle
                        strong limit: 
                        | Limite de registros a serem retornados. Como padrão, retornará os 50 primeiros.
                        br
                        strong last: 
                        | Último codigodb sincronizado, neste caso, só listará romaneios com o codigodb superior a 2


            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Retorno
                small(class="ml-1 font-weight-light") (vetor)
              v-expansion-panel-content
                p(class="mt-5")
                  | Esta requisição irá retornar um vetor JSON contendo objetos com mais de 100 campos com informações sobre os romaneios que tiveram
                  | envolvimento do CNPJ do terceiro,
                  | realizando filtro de informações sensíveis envolvendo informações de pessoas física para atender a LGPD.
              
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Possíveis códigos HTTP de retorno
              v-expansion-panel-content(class="ml-5")
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="success--text") 200
                  v-col(cols="12" sm="10") Sucesso
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 401
                  v-col(cols="12" sm="10") Token não informado
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Token inválido
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Sem permissão para acessar esta informação
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="red--text text--darken-2") 5xx
                  v-col(cols="12" sm="10") Erro interno na API do CS Integrador
            v-expansion-panel(:v-if="!!dadosRomaneios")
              v-expansion-panel-header(class="text-h6") JSON de retorno
              v-expansion-panel-content(class="overflow-y-auto" style="max-height: 200px") {{ dadosRomaneios }}


      // ESTORNOS


      v-timeline-item(small color="primary" class="timeline-box")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Romaneios Estornados 🚚
          v-card-text(class="white text-justify text--primary")
            p
              | A listagem de romaneios estornados funciona semelhante a lista de romaneios normais. Com a diferença que listará apenas os romaneios estornados.
              | Caso o romaneio já esteja estornado na data que é feita a requisição para a listagem de romaneios, o mesmo será exibido com o
              | campo 
              strong romaneio_estornado 
              | semelhante a 
              strong true
              | .
            v-row
              v-col(cols="12" sm="9")
                v-btn(class="mr-4 font-weight-bold" outlined color="success" v-on:click="carregarRomaneiosEstornados") GET
                span(class="primary--text") https://integrador.controlsoft.com.br/api/romaneios/estornados/:CNPJ
                v-btn(color="primary" icon v-on:click="carregarRomaneiosEstornados")
                  v-icon mdi-play-circle-outline
              v-col(cols="12" sm="3")
                v-select(dense label="Chave (CNPJ)" :items="cnpjs" small v-model="cnpjRomaneioEstorno" no-data-text="Sem CNPJs autorizados")             
          DataTable(:fields="fieldsRomaneios"
                    :dados="dadosRomaneiosEstornados"
                    :loaded="!!dadosRomaneiosEstornados"
                    :order="orderRomaneios")
          v-divider(class="mt-3")
          v-expansion-panels(accordion=false hover flat)
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Parâmetros informados na URL
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title cnpj
                    v-list-item-subtitle CNPJ do cliente ControlSoft que você deseja consultar os romaneios


            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Filtros possíveis
              v-expansion-panel-content
                v-list(three-line)
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Por ordem de romaneio
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/estornados/:CNPJ/:ROMANEIO
                      v-list-item-subtitle
                        strong ROMANEIO: 
                        | Deve ser um valor numérico,
                        | contendo o valor do campo 
                        strong codigodb 
                        | que é retornado na lista completa de romaneios
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Por data de estorno
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/estornados/:CNPJ/:DATAINICIAL/:DATAFINAL
                      v-list-item-subtitle
                        strong DATAINICIAL: 
                        | Data inicial no padrão YYYY-MM-DD (Ano-Mês-Dia)
                        br
                        strong DATA FINAL: 
                        | Data final no padrão YYYY-MM-DD (Ano-Mês-Dia)
                  v-list-item
                    v-list-item-content
                      v-list-item-title(class="text-h6") Ajustando os limites
                      v-list-item-title(class="primary--text")
                        | https://integrador.controlsoft.com.br/api/romaneios/estornados/:CNPJ?limit=5&last=2
                      v-list-item-subtitle
                        strong limit: 
                        | Limite de registros a serem retornados. Como padrão, retornará os 50 primeiros.
                        br
                        strong last: 
                        | Último codigodb sincronizado, neste caso, só listará romaneios com o codigodb superior a 2

            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Retorno
                small(class="ml-1 font-weight-light") (vetor)
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    p(class="mt-5")
                      | Esta requisição irá retornar um vetor JSON contendo objetos com mais de 100 campos com informações sobre os romaneios que tiveram
                      | envolvimento do CNPJ do terceiro,
                      | realizando filtro de informações sensíveis envolvendo informações de pessoas física para atender a LGPD.
                  
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Possíveis códigos HTTP de retorno
              v-expansion-panel-content(class="ml-5")
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="success--text") 200
                  v-col(cols="12" sm="10") Sucesso
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 401
                  v-col(cols="12" sm="10") Token não informado
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Token inválido
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Sem permissão para acessar esta informação
                v-row
                  v-col(cols="12" sm="2")
                    h2(class="red--text text--darken-2") 5xx
                  v-col(cols="12" sm="10") Erro interno na API do CS Integrador
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") JSON de retorno
              v-expansion-panel-content(class="overflow-y-auto" style="max-height: 200px") {{ dadosRomaneiosEstornados }}


      // ENVIO DE XML


      v-timeline-item(small color="primary" class="timeline-box")
        v-card(class="elevation-3")
          v-card-title(class="text-h5") Envio de NFe's 📤
          v-card-text(class="white text-justify text--primary")
            p
              | Este método serve para que seja enviado o arquivo XML relacionado à NFe envolvendo um dos romaneios recuperados através do CS Integrador
            v-btn(class="mr-4 font-weight-bold" outlined color="primary") POST
            span(class="primary--text") https://integrador.controlsoft.com.br/api/xmlnf/:CNPJ/:ROMANEIO

          v-divider(class="mt-3")
          v-expansion-panels(accordion=false hover flat)
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Parâmetros informados na URL
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title cnpj
                    v-list-item-subtitle CNPJ do cliente ControlSoft que você deseja consultar os romaneios
                v-list-item
                  v-list-item-content
                    v-list-item-title romaneio
                    v-list-item-subtitle
                      | Código do Romaneio. Deve ser um valor numérico, contendo o valor do campo 
                      strong codigodb
                      | que é retornado na lista completa de romaneios
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Parâmetros enviados como form data
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title arquivo
                      small(class="ml-1 font-weight-light")
                    v-list-item-subtitle Arquivo XML referente à nota ligada ao romaneio
              
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Retorno
              v-expansion-panel-content
                v-list-item
                  v-list-item-content
                    v-list-item-title message
                    v-list-item-subtitle Informa se o arquivo foi enviado com sucesso ou não
              
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Possíveis códigos HTTP de retorno
              v-expansion-panel-content(class="ml-5")
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="success--text") 201
                  v-col(cols="12" sm="10") Arquivo enviado com sucesso!
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="success--text") 202
                  v-col(cols="12" sm="10") Arquivo enviado com sucesso! Mas ocorreu um erro interno.
                    br 
                    small Erro não muito grave e que não trará impactos
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="orange--text text--darken-4") 401
                  v-col(cols="12" sm="10") Token não informado
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Token inválido
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="orange--text text--darken-4") 403
                  v-col(cols="12" sm="10") Sem permissão para envio de XML para este romaneio
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="orange--text text--darken-4") 415
                  v-col(cols="12" sm="10") Arquivo com formato invalido
                v-row
                  v-col(cols="12" sm="2")
                    h1(class="red--text text--darken-2") 5xx
                  v-col(cols="12" sm="10") Erro interno na API do CS Integrador
            v-expansion-panel
              v-expansion-panel-header(class="text-h6") Exemplo de JSON de retorno
              v-expansion-panel-content(class="overflow-y-auto" style="max-height: 200px") {{ JSONNfe }}

    v-divider
    v-row(class="text-center")
      v-col(class="ma-5")
        h3(class="text-h3 font-weight-light primary--text") Campos de Retorno
        p(class="text-center mt-5")
          | Como a consulta de romaneios retorna muitos campos, segue o detalhamento de cada um deles a seguir:
        div(class="d-flex justify-center")
          v-card
            v-simple-table(dense class="text-left" fixed-header height="300px")
              thead
                tr
                  th Campos
                  th Função
              tbody
                tr
                  th CODIGODB
                  td Código do romaneio (único)
                tr
                  th NUMERO
                  td Número do do romaneio (único)
                tr
                  th DATA_LCTO
                  td Data do lançamento do Romaneio no sistema
                tr
                  th HORA_LCTO
                  td Hora do lançamento do Romaneio no sistema
                tr
                  th NF_PRODUTOR
                  td Número NF Produtor Rural do Romaneio
                tr
                  th ESTOQUE
                  td Descrição da operação do estoque do Romaneio lançado (Entrada / Saída)
                tr
                  th OPERACAO
                  td Descrição da finalizada do Romaneio lançado
                tr
                  th EMPRESA
                  td Código e Razão Social da Empresa que está lançando o Romaneio
                tr
                  th CNPJ_EMPRESA
                  td CNPJ da Empresa que está lançando o Romaneio
                tr
                  th ARMAZEM
                  td Código e Descrição do armazém utilizados no lançamento do Romaneio
                tr
                  th SAFRA
                  td Código e Descrição da Safra utilizada no Romaneio
                tr
                  th ENTIDADE
                  td Código e Descrição da Entidade utilizada no Romaneio
                tr
                  th PROPRIEDADE
                  td Código e Descrição da Propriedade utilizada no Romaneio
                tr
                  th CPF_CNPJ_CLIENTE
                  td CPF / CNPJ da entidade utilizada no Romaneio
                tr
                  th IE_CLIENTE
                  td Inscrição Estadual da entidade utilizada no Romaneio
                tr
                  th PRODUTO
                  td Código e Descrição do produto utilizado no Romaneio
                tr
                  th VARIEDADE
                  td Código e Descrição da variedade do produto utilizado no Romaneio
                tr
                  th SUBVARIEDADE
                  td Código e Descrição da subvariedade do produto utilizado no Romaneio
                tr
                  th TALHAO
                  td Código e Descrição do talhão utilizado no Romaneio
                tr
                  th DESC_RCBTO
                  td Descrição da forma de desconto utilizada no Romaneio (Produto/Dinheiro)
                tr
                  th DESC_FRESERVA
                  td Descrição da forma de desconto para o fundo de reserva
                tr
                  th ARRENDATARIO
                  td Código e Descrição do arrendatário
                tr
                  th PROP_ARRENDATARIO
                  td Código e Descrição da propriedade do arrendatário
                tr
                  th CPF_CNPJ_ARRENDATARIO
                  td CPF / CNPJ da entidade arrendatária do Romaneio
                tr
                  th IE_ARRENDATARIO
                  td Inscrição Estadual da entidade arrendatária do Romaneio
                tr
                  th DESTINATARIO
                tr
                  th PROP_DESTINATARIO
                tr
                  th PESO_BRUTO
                  td Peso bruto do produto lançado no Romaneio
                tr
                  th PESO_BRUTO_PESAGEM
                  td Peso bruto do produto lançado no Romaneio
                tr
                  th DATA_PESO_BRUTO
                  td Data da captura do peso bruto
                tr
                  th HORA_PESO_BRUTO
                  td Hora da captura do peso bruto
                tr
                  th PESO_TARA
                  td Peso tara do produto lançado no Romaneio
                tr
                  th PESO_TARA_PESAGEM
                  td Peso tara do produto lançado no Romaneio
                tr
                  th DATA_PESO_TARA
                  td Data da captura do peso tara
                tr
                  th HORA_PESO_TARA
                  td Hora da captura do peso tara
                tr
                  th PESO_LIQUIDO
                  td Peso líquido do produto calculado no Romaneio
                tr
                  th VLR_UNITARIO
                  td Valor unitário do produto
                tr
                  th VLR_TOTAL
                  td Valor total do produto
                tr
                  th OBSERVACAO
                  td Observação livre informada no Romaneio
                tr
                  th TRANSGENERE
                  td Descritivo informando se a transgenia foi declarada, não declarada ou produto normal
                tr
                  th DESC_ROYALTIES
                  td Descritivo informando se desconta ou não royalties (S/N)
                tr
                  th PLACA_NF_TRANSPORTADOR
                  td Placa do transportador
                tr
                  th PESO_BRUTO_DESDOBRAMENTO
                  td Peso bruto vindo do desdobramento de carga
                tr
                  th PESO_TARA_DESDOBRAMENTO
                  td Peso tara vindo do desdobramento de carga
                tr
                  th TAXA_REC
                  td Taxa de desconto do produto
                tr
                  th QTD_REC
                  td Quantidade em Kg de desconto do produto
                tr
                  th VLR_REC
                  td Valor de desconto do produto
                tr
                  th TAXA_UMIDADE
                  td Taxa de desconto de umidade do produto
                tr
                  th FATOR_UMIDADE
                  td Fator de umidade do produto
                tr
                  th QTD_UMIDADE
                  td Quantidade de desconto de umidade do produto
                tr
                  th TAXA_IMPUREZA
                  td Taxa de desconto de impureza do produto
                tr
                  th FATOR_IMPUREZA
                  td Fator de impureza do produto
                tr
                  th QTD_IMPUREZA
                  td Quantidade de desconto de impureza do produto
                tr
                  th TAXA_ARDIDOS
                  td Taxa de desconto de ardidos do produto
                tr
                  th FATOR_ARDIDOS
                  td Fator de ardidos do produto
                tr
                  th QTD_ARDIDOS
                  td Quantidade de desconto de ardidos do produto
                tr
                  th TAXA_AVARIADOS
                  td Taxa de desconto de avariados do produto
                tr
                  th FATOR_AVARIADOS
                  td Fator de avariados do produto
                tr
                  th QTD_AVARIADOS
                  td Quantidade de desconto de avariados do produto
                tr
                  th TAXA_VERDES
                  td Taxa de desconto de verdes do produto
                tr
                  th FATOR_VERDES
                  td Fator de verdes do produto
                tr
                  th QTD_VERDES
                  td Quantidade de desconto de avariados do produto
                tr
                  th TAXA_QUEBRADAS
                  td Taxa de desconto de quebrados do produto
                tr
                  th FATOR_QUEBRADAS
                  td Fator de quebrados do produto
                tr
                  th QTD_QUEBRADAS
                  td Quantidade de desconto de quebrados do produto
                tr
                  th TAXA_ROYALTES
                  td Taxa de desconto de royalties do produto
                tr
                  th FATOR_ROYALTES
                  td Fator de royalties do produto
                tr
                  th QTD_ROYALTES
                  td Quantidade de desconto de royalties do produto
                tr
                  th TAXA_ACIDEZ
                  td Taxa de desconto de acidez do produto
                tr
                  th FATOR_ACIDEZ
                  td Fator de acidez do produto
                tr
                  th QTD_ACIDEZ
                  td Quantidade de desconto de acidez do produto
                tr
                  th TAXA_CARUNCHADOS
                  td Taxa de desconto de carunchados do produto
                tr
                  th FATOR_CARUNCHADOS
                  td Fator de carunchados do produto
                tr
                  th QTD_CARUNCHADOS
                  td Quantidade de desconto de carunchados do produto
                tr
                  th TAXA_MESA
                  td Taxa de desconto de mesa de gravidade do produto
                tr
                  th FATOR_MESA
                  td Fator de mesa de gravidade do produto
                tr
                  th QTD_MESA
                  td Quantidade de desconto de mesa de gravidade do produto
                tr
                  th NOME_MESA
                  td Desconto da mesa de gravidade
                tr
                  th TAXA_DESCONTO1
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO1
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO1
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO1
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO2
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO2
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO2
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO2
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO3
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO3
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO3
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO3
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO4
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO4
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO4
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO4
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO5
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO5
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO5
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO5
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO6
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO6
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO6
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO6
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO7
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO7
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO7
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO7
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO8
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO8
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO8
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO8
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO9
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO9
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO9
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO9
                  td Nome desconto configurável
                tr
                  th TAXA_DESCONTO10
                  td Taxa desconto configurável
                tr
                  th FATOR_DESCONTO10
                  td Fator desconto configurável
                tr
                  th QTD_DESCONTO10
                  td Quantidade desconto configurável
                tr
                  th NOME_DESCONTO10
                  td Nome desconto configurável
                tr
                  th ROMANEIO_ESTORNADO
                  td Status informando se o Romaneio está estornado (1/0)
                tr
                  th ORDEMCARREGAMENTO
                  td Código interno carregamento
                tr
                  th ORDEMNFTRANSPORTADOR
                  td Código interno carregamento
                tr
                  th ORDEMPARAMCLIENTE
                  td Código interno do transportador utilizado
                tr
                  th ORDEMITEMDESDOBRAMENTO
                  td Código controle interno
                tr
                  th ORDEMMOVCEREAL
                  td Código controle interno
                tr
                  th ORDEMPESAGEMELETRONICA
                  td Código interno da Pesagem eletrônica
                tr
                  th ORDEMCTTCOMER
                  td Código controle interno
                tr
                  th ORDEMCTTCOMERLOCAIS
                  td Código controle interno
                tr
                  th ORDEMLIGCULTIVARPRODUTOS
                  td Código controle interno
                tr
                  th DATA_ESTORNO
                  td Data que foi realizado o estorno do romaneio


    v-divider
    v-row(class="text-center")
      v-col(class="ma-5")
        h3(class="text-h3 font-weight-light primary--text") Aplicação de Exemplo
        p(class="text-center mt-5")
          | Através do botão abaixo, é possível baixar uma aplicação de exemplo,
          | que realiza a consulta a informações da API, utilizando componentes nativos do Delphi. 
        v-btn(outlined color="primary" href="https://integrador.controlsoft.com.br/exemplo.zip") Download

</template>

<script>
import { auth, clientes, romaneios } from '@/api'

export default {
  data: () => ({
    usuario: '',
    senha: '',
    senhaNova: '',
    dadosClientes: [],
    dadosRomaneios: [],
    dadosRomaneiosEstornados: [],
    cnpjRomaneio: null,
    cnpjRomaneioEstorno: null,
    fieldsClientes: clientes.fields,
    fieldsRomaneios: romaneios.fields,
    orderClientes: clientes.order,
    orderRomaneios: romaneios.order,
    cnpjs: [],
    JSONLogin: '{"access_token":"eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJkYWRvcyI6eyJpZCI6MSwiY3BmY25waiI6Ijk5OTk5OTk5OTk5OTk5Iiwibm9tZWZhbnRhc2lhIjoiTWV1IG5vbWUiLCJlbWFpbCI6bnVsbH0sImlhdCI6MTYyNjg5MzIxNywiZXhwIjoxNjI5NDg1MjE3fQ.tSy-K70mRrEzQciqBY4KHU3L5Z5yWF-4aqVjaS75czZdrro7Bue7Ssz7d608Kovl3SzSiA5WP5InRohba31LpQ","expires_in":86400,"user": {"id":1,"cpfcnpj":"99999999999999","nomefantasia":"Meu nome","email":null}}',
    JSONNfe: '{"message": "Arquivo enviado com sucesso!"}',
    showAlterarSenha: false,
    tentouAlterar: false,
    senhaAlterada: true,
    msgAlteracaoSenha: ''
  }),
  methods: {
    carregarClientes: async function () {
      try {
        this.dadosClientes = await clientes.getAll()
        this.cnpjs = this.dadosClientes.map(({cnpj}) => cnpj)
      } catch(e) {
        this.dadosClientes = []
        console.error(e)
        store.dispatch('alert/error',
          'Ocorreu algum problema ao buscar as informações',
          { root: true })
      }
    },
    carregarRomaneios: async function () {
      try {
        if (this.cnpjRomaneio) {
          this.dadosRomaneios = await romaneios.getAll(this.cnpjRomaneio)
        }
      } catch(e) {
        this.dadosRomaneios = []
        console.error(e)
        store.dispatch('alert/error',
          'Ocorreu algum problema ao buscar as informações',
          { root: true })
      }
    },
    carregarRomaneiosEstornados: async function () {
      try {
        if (this.cnpjRomaneioEstorno) {
          this.dadosRomaneiosEstornados = await romaneios.getEstornados(this.cnpjRomaneioEstorno)
        }
      } catch(e) {
        this.dadosRomaneiosEstornados = []
        console.error(e)
        store.dispatch('alert/error',
          'Ocorreu algum problema ao buscar as informações',
          { root: true })
      }
    },
    alterarSenha: async function() {
      try {
        this.tentouAlterar = true
        this.msgAlteracaoSenha = await auth.alterarSenha({ senha: this.senha, senhanova: this.senhaNova })
        .then(({ message }) => {
          this.senhaAlterada = true
          return message
        })
        .catch(( { response } ) => {
          this.senhaAlterada = false
          return response.data.message
        })
        
        
        this.showAlterarSenha = !this.senhaAlterada
        this.senha = ''
        this.senhaNova = ''
      } catch(e) {
        this.senhaAlterada = false
        console.error(e)
        store.dispatch('alert/error',
          'Ocorreu algum problema ao alterar a senha',
          { root: true })
      }
    }
  },
  mounted() {
    auth.refreshAuth(this.$store.state.auth)
    this.carregarClientes()    
  },
  name: 'Home',
  created() {
    this.$store.dispatch('auth/verify');
  },
};
</script>

<style scoped>
.timeline-box {
  margin-top: -200px;
}
</style>